import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from "react-i18next";
import i18n from '../i18n';
import { useNavigate, useLocation } from "react-router-dom";
import ReactLoading from 'react-loading';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Select from '@mui/material/Select';
import { SlRefresh } from "react-icons/sl";
//context
import UserContext from '../context/UserContext';
//api
import aws from '../api/aws';
//components
import { KgCO2e } from '../components/KgCO2e';
import IngredientRow from '../components/IngredientRow';
import { ButtonFull, ButtonEmptyNoSpace } from '../components/Buttons';
import Yield from '../components/Yield';
//util
import { colors, completeUnits, emptyUnit, urlHost, urlHostRegex, colorValuesRecipes } from '../util/values';
import { commonStyles } from '../util/styles';
import { sortBy, calculateCo2FromYield, getColor, getCount, getIngredientCo2, getIng } from '../util/climatecook';

const RecipeScreen = () => {
  const { t } = useTranslation();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [ingredientsWithoutChange, setIngredientsWithoutChange] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [newIngredients, setNewIngredients] = useState([]);
  const [id, setId] = useState(null);
  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name1Error, setName1Error] = useState("");
  const [name2Error, setName2Error] = useState("");
  const [totalCo2, setTotalCo2] = useState(0);
  const [rowErrors, setRowErrors] = useState({});
  const [portions, setPortions] = useState(1);
  const [portionsStr, setPortionsStr] = useState("1");
  const [cookTime, setCookTime] = useState("");
  const [imageUrl, setImageUrl] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [previousImageUrl, setPreviousImageUrl] = useState(null);

  const [availableUnits, setAvailableUnits] = useState([completeUnits[6]]);
  const [yieldKg, setYieldKg] = useState(0);

  const [categories, setCategories] = useState([{ id: null, name_eng: 'None', name_fr: 'Aucune', thresholdHigh: colorValuesRecipes.ingredientHigh, thresholdLow: colorValuesRecipes.ingredientLow }]);
  const [category, setCategory] = useState(categories[0]);
  const [menus, setMenus] = useState([]);
  const [menuInputs, setMenuInputs] = useState([]);
  const [menuInput, setMenuInput] = useState("");
  const [isNew, setIsNew] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [autoCompleteItems, setAutoCompleteItems] = useState([]);
  const [globalColor, setGlobalColor] = useState(colors.good);
  const [allIngs, setAllIngs] = useState([]);
  const [tab, setTab] = useState("overview");
  const [showModal, setShowModal] = useState(false);
  const [updateScroll, setUpdateScroll] = useState(0);
  const scrollBotRef = useRef(null);
  const [recipeHasChanged, setRecipeHasChanged] = useState(false);

  useEffect(() => {
    let nav = JSON.parse(localStorage.getItem("nav"));
    setTitle(nav != null ? nav[i18n.language].recipes : t('routeMyRecipes'));
    getCategories()
    getAllIngredients();
    getMenus();
  }, []);

  useEffect(() => {
    getIngredients();
  }, [categories]);

  useEffect(() => {
    calculateTotalCo2(newIngredients);
  }, [portions, newIngredients]);

  useEffect(() => {
    scrollBotRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [updateScroll]);

  const getMenus = async () => {
    const response = await aws.get('/menus', { params: { owner: user[0].user_id } });
    if (response.data) {
      let newMenus = [];
      for (let m of response.data) {
        newMenus.push(m.name);
      }
      setMenus(newMenus);
    }
  };

  const getCategories = async () => {
    const response = await aws.get('/categories', { params: { owner: user[0].user_id, isAll: true, isErn: false, isRecipeCat: 1 } });
    if (response.data) {
      let newCategories = [categories[0]];
      for (let cat of response.data) {
        newCategories.push({
          id: cat.id,
          name_eng: cat.name_eng,
          name_fr: cat.name_fr,
          thresholdHigh: cat.threshold_high === null ? colorValuesRecipes.ingredientHigh : cat.threshold_high,
          thresholdLow: cat.threshold_low === null ? colorValuesRecipes.ingredientLow : cat.threshold_low
        });
      }
      setCategories(newCategories);
    }
  };

  const getAllIngredients = async () => {
    setIsReady(false);
    const response = await aws.get('/ingredients', { params: { type: 'allIngs', owner: user[0].user_id, lang: i18n.language } });
    if (response.data) {
      let newAllIngs = [];
      let autoCompleteIngs = [];
      for (let i = 0; i < response.data.length; i++) {
        if (i18n.language === 'fr') {
          if (response.data[i].name_fra !== null && response.data[i].name_fra !== "") {
            autoCompleteIngs.push({ id: response.data[i].id, name: response.data[i].name_fra, ernIngCode: response.data[i].ernIngCode, owner: response.data[i].owner });
            newAllIngs.push(response.data[i].name_fra.toLowerCase());
          }
        } else {
          if (response.data[i].name_eng !== null && response.data[i].name_eng !== "") {
            autoCompleteIngs.push({ id: response.data[i].id, name: response.data[i].name_eng, ernIngCode: response.data[i].ernIngCode, owner: response.data[i].owner });
            newAllIngs.push(response.data[i].name_eng.toLowerCase());
          }
        }
      }

      for (let a of autoCompleteIngs) {
        /*let dupIndex = autoCompleteIngs.findIndex(o => o.id !== a.id && o.name === a.name);
        if (dupIndex !== -1) {
          if (a.owner === null) {
            a.name += " (ccresto)";
          } else if (autoCompleteIngs[dupIndex].owner != null) {
            autoCompleteIngs[dupIndex].name += " (" + autoCompleteIngs[dupIndex].owner + ")";
          }
        }*/
        if (a.owner === null) {
          a.name += " (ERN)";
        }
      }

      autoCompleteIngs = autoCompleteIngs.sort(sortBy('name', true, true));
      setAutoCompleteItems(autoCompleteIngs);
      setAllIngs(newAllIngs);
    }
  };

  const getIngredients = async () => {
    setIsReady(false);
    setIsNew(location.state.isNew ? location.state.isNew : false);

    let response;
    if (location.state.isNew) {
      response = { data: location.state.ingredient };
    } else {
      response = await aws.get(`/ingredients/composition/${location.state.ingredient[0].ingredient_id}`, { params: { user_id: user[0].user_id, owner: user[0].user_id, lang: i18n.language, region: 'CA' } });
    }
    setIngredientsWithoutChange(response.data);
    console.log(response.data);

    if (response.data) {

      let newIngredients = [];
      for (let i = 0; i < response.data.length; i++) {
        if (response.data[i].ing_level === 0) {
          newIngredients.push(getIng(response.data, response.data[i]));
        }
      }


      for (let i = 0; i < newIngredients.length; i++) {
        if (newIngredients[i].ing_level > 1) {
        } else if (newIngredients[i].main_ingredient_id === null) {
          setId(newIngredients[i].ingredient_id);
          setName1(i18n.language === 'fr' ? newIngredients[i].name_fra : newIngredients[i].name_eng);
          setName2(i18n.language === 'fr' ? newIngredients[i].name_eng : newIngredients[i].name_fra);
          setPortions(newIngredients[i].portions);
          setPortionsStr(newIngredients[i].portions !== 0 ? newIngredients[i].portions.toString() : '');
          setCookTime(newIngredients[i].cook_time ? newIngredients[i].cook_time : "");
          setImageUrl(newIngredients[i].image_url);
          setPreviousImageUrl(newIngredients[i].image_url);
          setMenuInputs(newIngredients[i].menus != null && newIngredients[i].menus !== "" ? newIngredients[i].menus.split(";;") : []);

          //getIng(newIngredients, newIngredients[i]);
          for (let j = 0; j < categories.length; j++) {
            if (categories[j].id == newIngredients[i].food_group) {
              setCategory(categories[j]);
            }
          }

          setYieldKg(newIngredients[i].yield_kg);
        } else {
          //newIngs.push({ ...response.data[i], uniqueId: getCount() });
        }
      }
      setIngredients(newIngredients[0].composition);
      setNewIngredients(newIngredients[0].composition);
    }

    setIsReady(true);
  };

  const calculateTotalCo2 = (itemList) => {
    let newTotalCo2 = calculateCo2FromYield(itemList);
    setTotalCo2(newTotalCo2);
    //update co2 only if co2 changes and recipe is the same
    if (newTotalCo2 !== 0 && !recipeHasChanged) {
      if (location.state.ingredient && location.state.ingredient[0].co2.toFixed(3) !== newTotalCo2.toFixed(3)) {
        for (let ing of newIngredients) {
          if (ingredientsWithoutChange.some((item) => (item.name_fra === ing.name_fra && item.name_eng === ing.name_eng && item.quantity === ing.quantity && item.unit === ing.unit))) {
            updateCo2(newTotalCo2);
            break;
          }
        }
      }
    }

    setGlobalColor(getColor(newTotalCo2, portions, category.thresholdHigh, category.thresholdLow));
  };

  const updateCo2 = async (newTotalCo2) => {
    await aws.put(`/ingredients/composition/${location.state.ingredient[0].ingredient_id}`, { co2: newTotalCo2 });
  };

  const onAddIngredient = () => {
    setRecipeHasChanged(true);
    let newIngredient = {
      ing_level: 1,
      main_ingredient_id: null,
      ingredient_id: null,
      unit: "...",
      quantity: 0,
      name_fra: "",
      name_eng: "",
      main_unit: "gr",
      owner: user[0].user_id,
      density: 1,
      unit_single: 1,
      unit_mass: 1,
      unit_volume: 1,
      unit_liquid: 1,
      unitWeight: 1,
      yield_kg: 1,
      yield_l: null,
      yield_unit: null,
      food_group: null,
      ern_ingredient_code: null,
      partner_ingredient_code: null,
      co2: 0,
      region: null,
      alternative: null,
      altErnIngCode: null,
      altPatnerIngCode: null,
      altCo2: null,
      altRegion: null,
      uniqueId: getCount()
    };
    setIngredients([...ingredients, newIngredient]);
    setNewIngredients([...newIngredients, newIngredient]);
    setUpdateScroll(updateScroll + 1);
  };

  const onDeleteIngredient = (uniqueId) => {
    setRecipeHasChanged(true);
    setIngredients(ingredients.filter(item => item.uniqueId !== uniqueId));
    let newNewIngredients = newIngredients.filter(item => item.uniqueId !== uniqueId);
    setNewIngredients(newNewIngredients);
    setRowErrors({ ...rowErrors, [uniqueId]: false });
  };

  const onChangeIngredient = (type, obj, uniqueId) => {
    setRecipeHasChanged(true);
    let newNewIngredients = [...newIngredients];
    for (let i = 0; i < newNewIngredients.length; i++) {
      if (newNewIngredients[i].uniqueId === uniqueId) {
        if (type === "quantity") {
          newNewIngredients[i].quantity = obj;
          //setIngredients(newNewIngredients);
        } else if (type === "unit") {
          newNewIngredients[i].unit = obj.name;
          setIngredients(newNewIngredients);
        } else if (type === "name") {
          newNewIngredients[i] = obj;
          setIngredients(newNewIngredients);
        }
        break;
      }
    }
    setNewIngredients(newNewIngredients);
  };

  const onChangeName = (which, value) => {
    if (which === 1) {
      setName1(value);
      if (value === "") {
        setName1Error(t("errorNameIsEmpty"));
      } else if ((i18n.language === 'fr' && value.toLowerCase() !== location.state.ingredient[0].name_fra.toLowerCase() && allIngs.includes(value.toLowerCase())) ||
        (i18n.language === 'en' && value.toLowerCase() !== location.state.ingredient[0].name_eng.toLowerCase() && allIngs.includes(value.toLowerCase()))) {
        setName1Error(t('errorNameAlreadyExists'));
      } else {
        setName1Error("");
      }
    } else {
      setName2(value);
      if ((i18n.language === 'fr' && value.toLowerCase() !== location.state.ingredient[0].name_fra.toLowerCase() && allIngs.includes(value.toLowerCase())) ||
        (i18n.language === 'en' && value.toLowerCase() !== location.state.ingredient[0].name_eng.toLowerCase() && allIngs.includes(value.toLowerCase()))) {
        setName2Error(t('errorNameAlreadyExists'));
      } else {
        setName2Error("");
      }
    }
  };

  const checkIfError = () => {
    if (totalCo2 == 0 || portions === 0 || name1Error !== "" || name2Error !== "" || name1 === "") {
      return true;
    }
    for (let key in rowErrors) {
      if (rowErrors[key] === true) {
        return true
      }
    }
    return false;
  };

  const onSaveIngredient = async () => {
    if (checkIfError()) {
      setShowModal(true);
      return;
    }
    setIsReady(false);

    let url = null;//`${urlHost}placeholder.jpg`;
    try {
      //let photoOwner1 = photoOwner;
      //if image url already exists else we upload it
      if ((imageUrl && !urlHostRegex.test(imageUrl) && !(/^blob:.+/).test(imageUrl)) || (!isNew && imageUrl && imageUrl === previousImageUrl)) {
        url = imageUrl;
      } else if (imageUrl) {
        const imageName = `${user[0].user_id}-r-${Date.now()}`;
        await AddPicture(imageName, imageUrl);
        url = `${urlHost}${imageName}.jpg`;
        //photoOwner1 = user.state.data.name;
      }
      //postRecipe(url, photoOwner1);
    } catch (err) {
      console.log(err);
      setIsReady(true);
      return;
    }


    const newYieldKg = yieldKg !== 0 ? yieldKg : null;

    let nameFr = name2;
    let nameEn = name1;
    if (i18n.language === "fr") {
      nameFr = name1;
      nameEn = name2
    }
    let cook_time = null;
    if (!isNaN(cookTime) && Number(cookTime) > 0) {
      cook_time = Number(cookTime);
    }
    let menus = [];
    for (let m of menuInputs) {
      menus.push(m);
    }

    let mainIngredient = {
      ...location.state.ingredient[0], name_fra: nameFr, name_eng: nameEn, yield_kg: newYieldKg, food_group: category.id,
      menus: menus, is_recipe: 1, portions, cook_time, recipeCo2: totalCo2, image_url: url
    };
    console.log(mainIngredient, newIngredients);
    await aws.post('/ingredients/composition', { owner: user[0].user_id, mainIngredient, isNew, ingredients: newIngredients });

    localStorage.removeItem("recipeValues");
    setIsReady(true);
    navigate("/recipes");
  };

  const AddPicture = async (imageName, image_) => {
    const response = await aws.post('/image', { userId: user[0].user_id, image_name: `${imageName}.jpg` });
    if (response.data) {
      await fetch(response.data, { method: "PUT", body: imageFile });
    }
  };

  const onChangePortions = (value) => {
    setRecipeHasChanged(true);
    setPortionsStr(value)
    let newValue = 0;
    if (!isNaN(value)) {
      newValue = Number(value);
    }
    setPortions(newValue);
  };

  const renderTab = (itemList) => {
    if (tab === 'overview') {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {imageUrl ? <img style={{ display: 'block', marginBottom: 20, width: 430, height: 280, borderRadius: 14, objectFit: 'cover' }} src={imageUrl} /> : null}
          <input id="uploadImageButton" type="file" accept="image/jpeg, image/png, image/jpg" onChange={(event) => { setImageUrl(URL.createObjectURL(event.target.files[0])); setImageFile(event.target.files[0]) }} hidden />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20, cursor: 'pointer' }} onClick={() => document.getElementById("uploadImageButton").click()}>
            <SlRefresh size={26} color={colors.text} />
            <div style={{ marginLeft: 20, color: colors.text }}>{t(imageUrl ? 'lblChangePhoto' : 'lblAddPhoto')}</div>
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 40px 1fr 1fr', gap: 20, alignSelf: 'stretch' }}>
            <div style={{ gridColumn: '3', gridRow: '1 /span 4' }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={styles.overviewLabel}>{t('lblCategory')}</div>
            </div>
            <Select sx={{ /*maxWidth: { xs: "99% !important", md: "99% !important" },*/ color: globalColor, fontFamily: 'Inter', fontWeight: 400, p: 0, ':after': { borderBottomColor: 'black' } }}
              variant="standard"
              value={category}
              onChange={(event) => setCategory(event.target.value)}>
              {categories.map(item => <MenuItem key={item.id + item.name_eng} value={item}>{i18n.language === 'fr' ? item.name_fr : item.name_eng}</MenuItem>)}
            </Select>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={styles.overviewLabel}>{t('lblPortions')}</div>
            </div>
            <TextField sx={{ input: { color: globalColor, fontFamily: 'Inter', padding: 0 }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
              error={portions === 0}
              helperText={portions === 0 ? t('errorNoPortions') : ''}
              id="portions"
              variant="standard"
              value={portionsStr}
              onChange={(event) => onChangePortions(event.target.value)}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={styles.overviewLabel}>{t('lblMenus')}</div>
            </div>
            <Autocomplete
              multiple
              freeSolo
              sx={{ input: { color: globalColor, fontFamily: 'Inter' }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
              value={menuInputs}
              onChange={(event, newValue) => { setMenuInputs(newValue) }}
              inputValue={menuInput}
              onInputChange={(event, newInputValue) => { setMenuInput(newInputValue) }}
              options={menus}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
              renderInput={(params) => <TextField {...params} /*style={{ minWidth: 200 }} */ id="menuName1" variant="standard" />}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={styles.overviewLabel}>{t('lblCommerces')}</div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ color: globalColor }}>{user[0].name}</div>
            </div>
            <div></div>
            <div></div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={styles.overviewLabel}>{t('lblCookTime')}</div>
            </div>
            <TextField sx={{ input: { color: globalColor, fontFamily: 'Inter', padding: 0 }, '& .MuiInput-underline:after': { borderBottomColor: 'black' } }}
              id="cookTime"
              variant="standard"
              placeholder={t('lblOptionnal')}
              value={cookTime}
              onChange={(event) => setCookTime(event.target.value)}
            />
          </div>
          <div></div>
        </div>
      );
    } else if (tab === 'ingredients') {
      return (
        <div>
          <table style={styles.table}>
            <tbody>
              {itemList.map((item) => {
                return <IngredientRow
                  key={item.uniqueId}
                  ingredient={item}
                  yieldKg={yieldKg}
                  totalCo2={totalCo2}
                  parentId={id}
                  autoComplete={autoCompleteItems}
                  onDeleteCallback={onDeleteIngredient}
                  onChangeCallback={onChangeIngredient}
                  onErrorCallback={(value) => setRowErrors({ ...rowErrors, [item.uniqueId]: value })}
                />
              })}
            </tbody>
          </table>
        </div>
      );
    } else if (tab === 'instructions') {
      return (
        <div></div>
      );
    }

  };

  return (
    <div style={commonStyles.container}>
      {showModal ? <div style={styles.modalWindow}>
        <div style={styles.modalDiv}>
          <div style={styles.modalText}>{portions === 0 ? t('errorPortions') : t('errorAlert')}</div>
          <div style={styles.modalButtons}>
            <ButtonFull text={t('btnOk')} onButtonClick={() => setShowModal(false)} />
          </div>
        </div>
      </div> : null}
      <div style={commonStyles.title}>{title}</div>
      <div style={commonStyles.body}>
        <div style={styles.topContainer}>
          <div style={styles.topButtonContainer}>
            <ButtonEmptyNoSpace text={t('btnCancel')} onButtonClick={() => navigate("/recipes")} />
            <ButtonFull text={t('btnSaveRecipe')} color={globalColor} onButtonClick={() => onSaveIngredient()} disabled={checkIfError()} isFakeDisabled={true} />
          </div>
          <div style={styles.topTitleContainer}>
            <div style={styles.titleRow}>
              <TextField sx={{ input: { ...styles.name1, color: globalColor, fontFamily: 'Inter', padding: 0 } }}
                InputProps={{ disableUnderline: true }}
                error={name1Error !== ""}
                helperText={name1Error}
                id="name1"
                variant="standard"
                placeholder={t('lblName', { lng: i18n.language === "fr" ? "fr" : "en" })}
                value={name1}
                onChange={(event) => onChangeName(1, event.target.value)}
              />
              <div style={{ ...styles.name1, color: globalColor }}>{t('lblRecipeImpact')}{portions == 0 ? '' : (totalCo2 / portions).toFixed(3)}</div>
            </div>
            <div style={styles.titleRow}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div style={{ ...styles.name2, color: colors.text }}>{i18n.language === "fr" ? "EN:" : "FR:"}</div>
                <TextField sx={{ input: { ...styles.name2, color: globalColor, fontFamily: 'Inter', padding: 0 } }}
                  InputProps={{ disableUnderline: true }}
                  error={name2Error !== ""}
                  helperText={name2Error}
                  id="name2"
                  variant="standard"
                  placeholder={t('lblName', { lng: i18n.language === "fr" ? "en" : "fr" })}
                  value={name2}
                  onChange={(event) => onChangeName(2, event.target.value)}
                />
              </div>
              <div><KgCO2e size={12} portion /></div>
            </div>
          </div>
          <div style={styles.topYieldContainer}>
            <div style={styles.yieldRow}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
              </div>
              <Yield yieldKey={0}
                label={t('lblYield')}
                availableUnits={availableUnits}
                unit={availableUnits[0]}
                onChangeUnit={() => { }}
                quantity={yieldKg}
                error={''}
                optionnal
                onChangeQuantity={(yieldKey, newValue) => setYieldKg(newValue)} />
            </div>
          </div>
          <div style={styles.tabs}>
            <div style={tab === 'overview' ? { ...styles.dividerText, backgroundColor: globalColor } : { ...styles.dividerText, ...styles.dividerTextUnselected }} onClick={() => setTab('overview')} >{t('tabOverview')}</div>
            <div style={tab === 'ingredients' ? { ...styles.dividerText, backgroundColor: globalColor } : { ...styles.dividerText, ...styles.dividerTextUnselected }} onClick={() => setTab('ingredients')}>{t('tabIngredients')}</div>
            <div style={{ width: 117 }}></div>
            {/*<div style={tab === 'instructions' ? { ...styles.dividerText, backgroundColor: globalColor } : { ...styles.dividerText, ...styles.dividerTextUnselected }} onClick={() => setTab('instructions')}>{t('tabInstructions')}</div>*/}
          </div>
          <div style={{ ...styles.divider, backgroundColor: globalColor }}></div>
          {tab === 'ingredients' ? <>
            <div style={styles.buttonContainer}>
              <ButtonFull text={t('btnAddIngredient')} color={globalColor} onButtonClick={() => onAddIngredient()} />
            </div>
            <table style={styles.table}>
              <tbody>
                <tr key="header">
                  <th style={{ ...styles.headerCell, width: 100 }}>{t('lblQuantity')}</th>
                  <th style={{ ...styles.headerCell, width: 50 }}>{t('lblUnit')}</th>
                  <th style={{ ...styles.headerCell, width: 420 }}>{t('lblName')}</th>
                  <th style={{ ...styles.headerCell, width: 100 }}>{t('lblAssociation')}</th>
                  <th style={{}}></th>
                </tr>
              </tbody>
            </table>
          </> : null}
        </div>
        {!isReady ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ReactLoading type="spin" color={colors.main} height={40} width={40} />
          </div> : renderTab(ingredients)
        }
        <div ref={scrollBotRef} />
      </div>
    </div>
  );
};

const styles = {
  topContainer: {
    paddingTop: 20,
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'sticky',
    top: 0,
    backgroundColor: colors.background,
    zIndex: 50
  },
  topButtonContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  topTitleContainer: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column'
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  name1: {
    fontSize: 20,
    fontWeight: 700
  },
  name2: {
    marginRight: 4,
    fontSize: 16,
    fontWeight: 700
  },
  topYieldContainer: {
    //alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'column'
  },
  yieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  yieldBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  yieldLabel: {
    marginRight: 20,
    width: 50,
    color: colors.text,
  },
  yieldInput: {
    pb: 0,
    width: 50,
    color: colors.text
  },
  yieldUnit: {
    marginLeft: 24,
    width: 50,
    fontSize: 15,
    fontWeight: 600,
    color: colors.darkGray,
    textAlign: 'center',
    backgroundColor: colors.grey,
    borderRadius: 6,
    borderStyle: 'solid',
    borderColor: colors.darkGray,
    borderWidth: 1,
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  dividerText: {
    padding: '10px 15px 6px 15px',
    fontWeight: 600,
    color: colors.textNegative,
    borderRadius: '10px 10px 0 0',
    cursor: 'pointer',
    transition: 'background-color 0.2s, color 0.2s'
  },
  dividerTextUnselected: {
    color: colors.text,
  },
  divider: {
    height: 6,
    marginBottom: 20
  },

  buttonContainer: {
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse'
  },
  row: {
    backgroundColor: colors.semiLightGrey
  },
  headerCell: {
    padding: '0 20px',
    fontWeight: 400,
    textAlign: 'left',
  },
  cell: {
    padding: '10px 20px',
    fontSize: 16,
    fontWeight: '500',
  },
  firstCell: {
    width: 6,
  },
  gap: {
    width: 6,
    backgroundColor: colors.background,
  },
  lastCell: {
    width: 80,
    textAlign: 'center',
    backgroundColor: colors.background
  },

  modalWindow: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  },
  modalDiv: {
    width: 200,
    padding: '30px 40px',
    borderRadius: 12,
    borderStyle: 'solid',
    borderColor: colors.main,
    borderWidth: 2,
    backgroundColor: colors.background,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
  },
  modalText: {
    marginBottom: 20,
    textAlign: 'center'
  },
  modalButtons: {
    display: 'flex',
    justifyContent: 'space-around'
  },

  overviewLabel: {
    color: colors.text,
    fontWeight: 400
  },
  overviewCategory: {
    //fontSize: 16
  },
};

export default RecipeScreen;